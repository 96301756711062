import styled from "styled-components";



export const ScrollSectionTitle = styled.h2`
    font-size: 36px;
    line-height: 50px;
    margin-bottom: 48px;
`;

export const ScrollSectionWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;


export const ScrollButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
`;

export const InnerButtonWrapper = styled.div`
    padding-bottom: 16px;

    width: 100%;
    max-width: 328px;
`;