import styled from 'styled-components';
import { getCalculatorComparisonBreakpoint } from '../../../style/SavingsCalculation/SavingsCalculation';


export const CalculationComparisonStripeWrapper = styled.div`
    padding: 40px 20px;

    background-color: white;

    ${props => {

        if(!props.boundToCalculator)
        {
            return ``;
        }


        return `
            @media (min-width: ${getCalculatorComparisonBreakpoint(props)}) {
                display: none;
            }
        `;

    }}
`;