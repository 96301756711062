import styled from "styled-components";


export const TextBlockStripeWrapper = styled.div`
    padding: 66px 144px;

    @media screen and (max-width: ${props => props.theme.breakpoints.md}) {
        padding: 40px 20px;
    }

    color: ${props => props.theme.colors.green.dark};

    h1, h2 {
        font-size: 42px;
        line-height: 52px;

        @media (max-width: ${props => props.theme.breakpoints.md}) {
            font-size: 28px;
            line-height: 38px;
        }
    }

    h3 {
        font-size: 18px;
        line-height: 24px;
        font-weight: 500;
    }

    font-size: 18px;
    line-height: 24px;

    @media (max-width: ${props => props.theme.breakpoints.md}) {
        font-size: 16px;
        line-height: 24px;
    }
    
    .stripe__line-break {
        @media (min-width: ${props => props.theme.breakpoints.md}) {
            display: none;
        }
    }

    p {
        margin-bottom: 48px;
    }
`;

