import React from "react";
import { TextBlockStripeWrapper } from "./styles/text-block-stripe-styles";
import { MarkupToHtml } from "../../helpers/parse";

export const TextBlockStripe = ({ text, keepParagraphsSeparate = false, afterContent = undefined }) =>
{

    const components = {
        br: () => <><br /><br className="stripe__line-break" /></>,
        a: (props) => <a className="underline hover:no-underline cursor-pointer text-color-inherit" {...props} />,
    };

    if(keepParagraphsSeparate)
    {
        components.p = (props) => <p {...props} />;
    }

    return (
        <TextBlockStripeWrapper>
            <div>
                <MarkupToHtml
                    components={components}
                >
                    {text}
                </MarkupToHtml>
                {afterContent}
            </div>
        </TextBlockStripeWrapper>
    );
}