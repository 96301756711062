

const getAnalyticsFromPageLead = (pageLead) =>
{
    const { leadSourceId, leadAnalytics } = pageLead.data.attributes;

    const resultObject = {
        section: leadAnalytics.section,
        text: leadAnalytics.text,
        type: leadAnalytics.type,
        sourceId: leadSourceId,
    }

    if (leadAnalytics.name)
    {
        resultObject.name = leadAnalytics.name;
    }

    return resultObject;
}

export const getContactFormPropsFromPageLead = (pageLead) =>
{
    const { leadSourceId } = pageLead.data.attributes;

    return {
        leadsource: leadSourceId,
        analytics: getAnalyticsFromPageLead(pageLead),
    }

}