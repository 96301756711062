import React from "react";
import { ScrollButtonWrapper, ScrollSectionWrapper, ScrollSectionTitle, InnerButtonWrapper } from "../styles/scroll-to-calculation-section";
import { DEFAULT_CALCULATOR_ID } from "../SavingsCalculation";
import { Button } from "../../../style/Button";




export const ScrollToCalculatorSection = ({ title, buttonText, targetHash = DEFAULT_CALCULATOR_ID }) => {
    const handleScrollToCalculationButtonClick = () => {
        window.location.hash = `#${targetHash}`;
    }

    return (
        <ScrollSectionWrapper>
            
            <ScrollSectionTitle>
                {title}
            </ScrollSectionTitle>

            
            <ScrollButtonWrapper>
                <InnerButtonWrapper>
                    <Button
                        width="100%"
                        height="55px"
                        fontSize="18px"
                        fontWeight="600"
                        onClick={handleScrollToCalculationButtonClick}
                    >
                        {buttonText}
                    </Button>
                </InnerButtonWrapper>
            </ScrollButtonWrapper>
        </ScrollSectionWrapper>
    )

};