import React from "react";
import { CalculationComparisonImage } from "./CalculationComparisonImage";
import { CalculationComparisonStripeWrapper } from "../styles/calculation-comparison-stripe-styles";


export const CalculationComparisonStripe = ({
    leftImage,
    rightImage,
    boundToCalculator,

    customStyles = undefined,
}) =>
{

    return (
        <CalculationComparisonStripeWrapper
            boundToCalculator={boundToCalculator}
            style={customStyles?.stripeWrapper}
        >
            <CalculationComparisonImage
                leftImage={leftImage}
                rightImage={rightImage}
            />
        </CalculationComparisonStripeWrapper>
    )

}