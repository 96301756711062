import { graphql } from "gatsby";
import React from "react";
import { CALCULATOR_IMAGE_TYPES, SavingsCalculation } from "../components/SavingsCalculation/SavingsCalculation";
import { Helmet } from "react-helmet";
import MainLayout from "../componentsTailwind/MainLayout";
import { OFFER_TYPE } from "../offers/offer-types";
import { getPageCalculatorFullProps } from "../calculator/page-calculator-prop-utils";
import { CalculationComparisonStripe } from "../components/SavingsCalculation/components/CalculationComparisonStripe";
import { getImageCalculatorFromPageCalculator } from "../calculator/calculator-image-utils";
import { TextBlockStripe } from "../components/Text/TextBlockStripe";
import PhoneContactFormWrapper from "../components/LeadForms/PhoneContactFormWrapper";
import ContactForm from "../components/contactForm";
import { getContactFormPropsFromPageLead } from "../components/ContactForm/utils/contact-form-props-from-page-lead";
import { useCalculationResultChange } from "../components/SavingsCalculation/hooks/use-calculation-result-change";
import { ScrollToCalculatorSection } from "../components/SavingsCalculation/components/ScrollToCalculatorSection";


const RegulatedPricesPage = props =>
{
  const { data } = props;

  const regulatedPricePageData = data.mnd.regulatedPrice.data.attributes;
  const pageCalculator = regulatedPricePageData.calculator;
  const pageLeadSource = regulatedPricePageData.leadSource;

  const webCalculator = data.mnd.webCalculator.data.attributes;

  const offers = data.mnd.offer.data.attributes.offers.filter(offer => offer.offerAssignment === OFFER_TYPE.Homepage);


  const calculatorImage = getImageCalculatorFromPageCalculator(pageCalculator);


  const {
    onResultChange,
    consumption,
    commodity,
    consumptionHighTariff
  } = useCalculationResultChange();


  const { title: scrollTitle, buttonText: scrollButtonText } = regulatedPricePageData.scrollToCalculatorSection ?? {};

  const hasScrollToCalculationData =
    regulatedPricePageData.scrollToCalculatorSection
    && scrollTitle
    && scrollButtonText;

  return (
    <MainLayout>
      <Helmet>
        <title>Regulované ceny | MND</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>

      {calculatorImage.type === CALCULATOR_IMAGE_TYPES.COMPARISON ?
      <CalculationComparisonStripe
        boundToCalculator

        customStyles={{
          stripeWrapper: {
            paddingBottom: "0px"
          },
        }}

        leftImage={{
          url: calculatorImage.leftImageUrl,
        }}

        rightImage={{
          url: calculatorImage.rightImageUrl,
        }}
      /> : null}



      {regulatedPricePageData.pageIntroductoryText ? <TextBlockStripe
        text={regulatedPricePageData.pageIntroductoryText}
      /> : null}




      <SavingsCalculation
        offers={offers}
        data={webCalculator}
        onResultChange={onResultChange}
        {...getPageCalculatorFullProps({
          pageCalculator,
        })}

      />

      {regulatedPricePageData.pageAfterCalculatorText ? <TextBlockStripe
        text={regulatedPricePageData.pageAfterCalculatorText}
        keepParagraphsSeparate
        afterContent={hasScrollToCalculationData ?
          <ScrollToCalculatorSection
            title={scrollTitle}
            buttonText={scrollButtonText}
          />
        : null}
      /> : null}

      <PhoneContactFormWrapper>
        <ContactForm
          enableCallbackTime={true}
          enableEmail={false}
          
          type="PAGE_HP"
          commodity={commodity}
          consumption={consumption * 1000}
          consumptionHighTariff={consumptionHighTariff * 1000}

          {...getContactFormPropsFromPageLead(pageLeadSource)}
        />
      </PhoneContactFormWrapper>


    </MainLayout>
  );
};

export const query = graphql`
  query RegulatedPricesPageQuery {
    mnd {
      regulatedPrice {
        data {
          attributes {

            pageIntroductoryText
            pageAfterCalculatorText

            scrollToCalculatorSection {
              title
              buttonText
            }

            calculator {
              ...PageCalculatorFromCollection
            }

            leadSource {
              ...LeadSourceFromCollection
            }

            # End of content
          }
        }
      }

      ...WebCalculator

      ...FullOffers

    }
  }
`;

export default RegulatedPricesPage;
